@import 'variables';
@import 'structure';
@import 'freelancer';
@import 'typography';
@import 'components';

body {
  background: var(--body-background);
  color: var(--silver-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 ;
  font-size: 100%;
  line-height: 1.5;
}
body.reg-layout {
  background: var(--silver-color);
  color: black;
}
a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: var(--action-color);
}
a:hover {
  text-decoration:none;
}

main {
  display:initial;
  margin: 0rem auto;
  max-width: 100%;
  min-height: calc(100vh - 200px);
  padding: 0;
  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}
.reg-layout main {
  padding: 3.75rem;
  border-left: 2px solid black;
  min-height: 100vh;
  margin-left:initial;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 500px) {
    margin-top: auto;
    margin-left: auto;
    border-left:none
  }
  background-color:transparent;
}
.reg-layout.index main {
  border-left: none;
}
@media (max-width: 960px) {
  .reg-layout main {
    padding: 1rem;
    border-top: 2px solid black;
  }
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
  height: auto;
  margin-bottom: 0;
  padding: 3.75rem;
}

.reg-layout footer {
  border-top: 2px solid black;
}

/* Links */
footer a { padding: 0.10rem 0.35rem; text-decoration: none;}
footer a:hover {color:black;background:var(--action-color); padding: 0.10rem 0.35rem; text-decoration: none;}
