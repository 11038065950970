/* The structure file */
.page { /* contains the whole page. Able to be replaced */
	display:flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: auto;
	min-height: calc(100vh + 10rem);
}

.row {
	display:flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: auto;
	align-items: center;
}
.row.vertically-padded { /* padded vertically */
	padding-top: 10vh;
	padding-bottom: 10vh;
}
@media (max-width: 800px) {
	.row.vertically-padded { /* padded vertically */
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}
.row.tall-boy { /* At least as tall as the page. */
	min-height: calc(100vh - 11rem );
}

.row.darkened    { background-color: var(--darkened-color); }
.row.really-dark { background-color: var(--off-black-color); }
.row.brightened  { background-color: var(--silver-color); }

/* Brightened row stuff */
.row.brightened h2,
.row.brightened p { color: var(--darkened-color); }

/* Giving sections flexbox columns*/
.columned {
	display:flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}
@media (max-width: 900px) {
	.columned {
		flex-direction: column;
	}
}

.reverse-columned {
	display:flex;
	flex-direction: row-reverse;
	align-items: flex-start;
	justify-content: space-between;
}
@media (max-width: 900px) {
	.reverse-columned {
		flex-direction: column;
	}
}

/* Text-alignment and stuff */
.ta-left { text-align:left; }
.ta-right { text-align:right; }
.ta-center { text-align:center; }
.ta-justify { text-align:justify; }

/* typeface measure */
.widescreen     { width: 100%; max-width: 100rem; } /* 1600px */
.really-wide    { width: 100%; max-width: 80.25rem; } /* 1284px - Add an 80px padding here. */
.full-measure   { width: 100%; max-width: 60rem; } /* 960px */
.half-measure   { width: 100%; max-width: 30rem; } /* 480px */
.wide-measure   { width: 100%; max-width: 40rem; } /* 640px */
.measure        { width: 100%; max-width: 32.5rem; } /* 520px */
.narrow-measure { width: 100%; max-width: 20rem; } /* 320px */

/* Give sections padding */
.padded-section {
	padding: 5rem;
	box-sizing:border-box;
}


@media (max-width: 800px) {
	.padded-section {
		padding: 2rem;
	}
}

@media (max-width: 500px) {
	.padded-section {
		padding: 1rem;
		max-width:100vw;
		overflow:hidden;
	}
}

/* New layout for most pages that feature sidebar */
/* .reg-layout on the body means it's got the sidebar */
.reg-layout {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-columns:11rem 1fr;
	grid-template-rows:10rem 1fr 13rem;
	grid-template-areas:
		"side main"
		"menu main"
		"footer"
}

.reg-layout .item-side {
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 2;
}

.reg-layout .item-menu {
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 2;
	grid-row-end: 3;
}

.reg-layout .item-main {
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 3;
}

.reg-layout .item-footer {
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 3;
	grid-row-end: 4;
}

@media (max-width: 960px) {
	.reg-layout .item-side {
		grid-column-start: 1;
		grid-column-end: 4;
		grid-row-start: 1;
		grid-row-end: 2;
	}

	.reg-layout .item-menu {
		grid-column-start: 2;
		grid-column-end: 4;
		grid-row-start: 1;
		grid-row-end: 2;
	}

	.reg-layout .item-main {
		grid-column-start: 1;
		grid-column-end: 4;
		grid-row-start: 2;
		grid-row-end: 3;

		/* To hide the menu on mobile */
		z-index:1000;
		transform: translateY(-58px);
		background: var(--silver-color);
		border-left: none;
		transition: all 0.3s ease-in-out;
	}
	.reg-layout .item-main.nudged {
		transform: translateY(0);
	}
}

columns {
	display:flex;
	position:relative;
	flex-direction: row;
	justify-content: space-between;
	column-gap: 2rem;
	row-gap: 2rem;
	margin-bottom: 4rem;
}
column {
	display:block;
	position:relative;
	border-radius: 10px;
	flex-grow: 1;
	width: 100%;
}
column > *:first-child {
	margin-top: 0rem !important;
}
column > *:last-child {
	margin-bottom: 0 !important;
}

@media (max-width: 600px) {
	columns {
		flex-direction: column;
		row-gap: 1rem;
	}
}

/*index page*/
.reg-layout.index {
	grid-template-columns: 20rem 1fr;
	grid-template-rows: 10rem 1fr 13rem;
	grid-template-areas:
	"side menu"
	"main main"
	"footer footer";
}

.reg-layout.index .item-main {
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 3;
	padding: 1rem;
}

.reg-layout.index .item-menu {
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 2;
}

body.reg-layout.index > nav ul {
	flex-direction: row;
	margin: 0;
	justify-content: end;
}
