@import 'variables';
/* freelancer.css */


/* Typography  */
@import 'typography';

/* refacter this next part to be on the freelancer namespace instead of be the default. */
body {
	background-color:var(--darkened-color);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
}
main {
	background-color: none;
}

/* Really Cool Knight Graphic */
.ostentatious-font { /* Used for the logo in the header. */
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 800;
	font-size: 17rem;
	line-height: 1em;
	letter-spacing:-0.06em;
	color: var(--action-color);
	margin:0;
	position: absolute;
	top: calc(50% - 0.5em);
	left:0;
	right:0;
	width: inherit;
	max-width: 100vw;
	height: 1em;
	margin: 0 0 0 -.06em;
}

.row.horsey {
	overflow:hidden;
}

.ostentatious-font-container {
	overflow: hidden;
	position: absolute;
	margin:0;
	top: 50%;
	margin-top:-3.5em;
	left:0;
	width: 200vw;
	max-width: 200vw;
	height: 12rem;
	z-index: -1000;
	left: 50%;
	margin-left: -100%;
}

.knight {
	width: 100%;
	max-width: 1200px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	z-index: 1000;
	margin-top:-5rem;
	margin-bottom:-5rem;
}

.flavor-text-1 {
	background: url("/images/to-fight-thine-type-image-white.svg");
	background-position: center center;
	background-size:contain;
	background-repeat:no-repeat;
	text-indent: -10000px;
	text-color: transparent;
	font-size: 0px;
	display:block;
	position: relative;
	height: 125px;
	width:100%;
	max-width: 626px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:-2rem;
}
.flavor-text-2 {
	background: url("/images/a-sellsword-type-image-white.svg");
	background-position: center center;
	background-size:contain;
	background-repeat:no-repeat;
	text-indent: -10000px;
	text-color: transparent;
	font-size: 0px;
	display:block;
	position: relative;
	height: 190px;
	width:100%;
	max-width: 442px;
	margin-left:auto;
	margin-right:auto;
	margin-top:-2rem;
}

.flavor-text-1, .flavor-text-2 {
	-webkit-transition: transform 0.3s 0s ease;
	-moz-transition: transform 0.3s 0s ease;
	-o-transition: transform 0.3s 0s ease;
	transition: transform 0.3s 0s ease;
}
@media (max-width: 800px) {
	.flavor-text-1, .flavor-text-2 {
		transform: scale(0.75);
	}
}
@media (min-width: 1300px) {
	.flavor-text-1, .flavor-text-2 {
		transform: scale(1.25);
	}
}

/* Components */

.button {
	display:inline-block;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	line-height: 2.75rem;
	height: 2.75rem;
	font-size: 1.5rem;
	color: white;
	width: auto;
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 600;
	text-decoration:none;
	background: var(--action-color);
	border-radius: 22px;
	min-width: 12rem;
	margin-top:1rem;
	margin-bottom:1rem;
	box-sizing: border-box;
}
.special-ordered-list {
	/* padding-left: 6.25rem; */
	padding: 0;
	margin: 0;
	list-style: none;
}
.special-ordered-list li {
	margin-bottom: 1.5rem;
	padding-left: 6.25rem;
}
.special-ordered-list li:before {
	content: '';
	color:var(--action-color);
	font-weight: 600;
	font-size: 3.75rem;
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
	display: block;
	position:relative;
	text-align: left;
	width: 6.25rem;
	transform: translateX(-6.25rem) translateY(2.5rem);
}

.special-ordered-list li:nth-child(1):before {
	content: '1.';
}

.special-ordered-list li:nth-child(2):before {
	content: '2.';
}

.special-ordered-list li:nth-child(3):before {
	content: '3.';
}

.special-ordered-list li:nth-child(4):before {
	content: '4.';
}

.patterned-row {
	display:flex;
	height: calc(100vh - 11rem );
	flex-direction: column;
	justify-content: center;
}

.logo-thing {
	display:block;
	width:200px;
	height:200px;
	background-image: url("/images/logo.png");
	background-image: url("/images/logo-original.png");
	background-size: 200px 200px;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-transition: transform 0.1s 0s ease;
	-moz-transition: transform 0.1s 0s ease;
	-o-transition: transform 0.1s 0s ease;
	transition: transform 0.1s 0s ease;
	margin-bottom: -10rem;
}

.withered { /* for text that is not all there. */
	opacity: 0.9;
}

/* Pricing Toggle */
.pricing-toggle-container {
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	gap:10px;
	position: relative;
	width: 100%;
	max-width: 20rem;
	margin: 0rem auto 5rem;
	border: solid 3px var(--pr-brand-yellow);
	border-radius:20px;
	padding: 10px;
	box-sizing: border-box;
	position:absolute;
	left:50%;
	bottom:0%;
	margin-left:-10rem;
	margin-bottom:-8rem;
}

.pricing-toggle-container .toggle {
	display:block;
	position:relative;
	cursor:pointer;
	font-size: 1.125rem;
	font-weight:600;
	line-height:2.5rem;
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
	width:150px;
	text-align:center;
	height: 44px;
	border-radius:10px;
	background: var(--pr-brand-yellow);
	opacity: 1.0;
	color:black;
	border:none;
	transform: scale(1);
	transition: transform 0.13s ease-in-out, opacity 0.13s ease-in;
	user-select: none;
}
.pricing-toggle-container .toggle:hover {
	transform: scale(1.05);
}

.pricing-toggle-container .toggle.inactive {
	opacity: 0.5;
}

/* Card Container */
.card-container {
	display:flex;
	flex-direction:row;
	justify-content:center;
	flex-wrap:wrap;
	margin:0;
	gap: 5rem;
}
.swapper {
	display:flex;
	position:relative;
	width:28rem;
	height:auto;
	/* overflow:hidden; */
	gap: 1rem;
}
.price-card {
	width: 100%;
	max-width:28rem;
	height:auto;
	min-height:500px;
	box-sizing:border-box;
	padding:4rem 3rem 2rem;
	border-radius:2.5rem;
	background-color: var(--pr-brand-midnight);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: transform 0.27s ease-in-out, opacity 0.27s ease-in;
	opacity:1.0;
	transform: translateX(0rem);
}
/* .price-card.faded-left {
	transform: translateX(-5rem);
	opacity:0;
	pointer-events: none;
}
.price-card.faded-right {
	transform: translateX(5rem);
	opacity:0;
	pointer-events: none;
} */
/* .swapper .price-card:first-child { margin-right:-170px; }
.swapper .price-card:last-child { margin-left:-170px; } */

/* .swapper .price-card.faded:first-child {
	transform: translateX(-5rem);
	opacity:0;
	pointer-events: none;
}
.swapper .price-card.faded:last-child {
	transform: translateX(5rem);
	opacity:0;
	pointer-events: none;
} */

.price-card h2, .price-card p {
	color: var(--off-white-cream-color);
	font-size:1.5rem;
}
.price-card h2 {
	font-size:3rem;
	line-height:1em;
}
.price-card p.price {
	font-family: 'ManifoldCF', 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 800;
	font-size: 4.5rem;
	line-height: 4.5rem;
	text-align:center;
	margin-bottom:0;
}
.price-card p.price span {
	font-size: 1.5rem;
	font-weight: 500;
}
.price-card p.price-subtext {
	font-size: 2rem;
	line-height: 3rem;
	text-align: center;
	/* margin-top:-1rem; */
	font-weight:400;
}
.price-card .chin {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.price-card .chin .button {
	margin: 2rem 0 0.5rem;
}
.price-card .chin p:first-child {
	height: 8.75rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.price-card .button {
	/* display:block; */
	margin: 4rem auto;
	text-align: center;
	color: var(--off-white-cream-color);
	font-weight: 500;
	/* font-size: 1.125rem; */
	font-size: 2rem;
	height: auto;
	width: 100%;
	line-height: 3.5rem;
	border-radius: 54px;
	transition: transform 0.13s ease-in-out, box-shadow 0.13s ease-in;
	transform: scale(1);
	box-shadow: 0px 0px 3px 0px var(--action-color);
}
.price-card .button:hover {
	transform: scale(1.11);
	box-shadow: 0px 0px 14px 0px var(--action-color);
}

/* price card info */
.price-card.outlined {
	background: transparent;
	border: solid 4px var(--off-white-cream-color);
	color: var(--off-white-cream-color);
	padding: 3rem 2.25rem;
}
@media (max-width: 1134px) { .price-card.outlined {margin-top: 7rem; } }

body.reg-layout .price-card.outlined {
		border: solid 3px black;
		color: black;
}

.price-card.outlined p, .price-card.outlined h3 {
	color: var(--off-white-cream-color);
	opacity:0.8;
}
body.reg-layout .price-card.outlined p, body.reg-layout .price-card.outlined h3 { color: black; }
.price-card.outlined h3 {
	font-size: 2rem;
	line-height:1em;
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
}
.price-card.outlined p {
	font-size: 1.5rem;
	line-height:1.5em;
	font-weight: 400;
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
}

/* @media (min-width: 1201px) and (max-width: 1400px) {
	body.reg-layout .card-container {
		justify-content: space-around;
	}
	body.reg-layout .price-card {
		margin-bottom: 5rem;
	}
	body.reg-layout .price-card.outlined {
		max-width: 100%;
		display:flex;
		justify-content: space-between;
		gap: 5rem;
		min-height: auto;
	}
	body.reg-layout .price-card.outlined div {
		width: 50%;
	}
} */

/* @media (max-width: 1200px) {
	.card-container {
		justify-content: space-around;
	}
	.price-card {
		margin-bottom: 5rem;
	}
	.price-card.outlined {
		max-width: 100%;
		display:flex;
		justify-content: space-between;
		flex-direction:row;
		gap: 5rem;
		min-height: auto;
	}
	.price-card.outlined div {
		width: 50%;
	}
} */

/* @media (max-width: 800px) {
	.price-card.outlined {
		max-width: 360px;
		display:block;
	}
	.price-card.outlined div {
		width: initial;
	}

	/* Typography changes */
	/* .heading-one {
		font-size: 3rem;
		line-height: 3rem;
	}
	.heading-two {
		font-size: 2.25rem;
		line-height: 2.25rem;
	} */
/* }  */

/* The White thing. */
.patterned-row .title-logo,
.patterned-row .excerpt { /* Used for the logo in the header. */
	color: var(--darkened-color);
	opacity: 1.0;
	z-index: 1000;
}

body.freelance-page > nav.home-nav {
	position:fixed;
	z-index: 10000;
	width: 100%;
}
body.freelance-page > nav.home-nav ul {
	padding-right:2rem;
	justify-content: right;
}
body.freelance-page > nav.home-nav a {
	color: white;
	background-color:transparent;
	padding:0.5rem 2rem;
	border-radius:50px;
	border-color:white;
	border-style:solid;
	border-width:3px;
	-webkit-transition: all 0.3s 0s ease;
	-moz-transition: all 0.3s 0s ease;
	-o-transition: all 0.3s 0s ease;
	transition: all 0.3s 0s ease;
}
body.freelance-page > nav.home-nav a:hover {
	background-color:white;
	color:black;
}


/* Designer Row */
.designer-row {
	box-shadow:
	0 -10px 0 var(--pr-brand-yellow),
	0 -20px 0 var(--darkened-color),
	0 -30px 0 var(--pr-brand-apricot),
	0 -40px 0 var(--darkened-color),
	0 -50px 0 var(--pr-brand-magenta),
	0 -60px 0 var(--darkened-color),
	0 -70px 0 var(--pr-brand-purple),
	0 -80px 0 var(--darkened-color),
	0 -100px 0 var(--pr-brand-midnight);
	/* margin-top: 16rem; */
	padding-top: 0 !important;
	position:relative;
	overflow:hidden;
}

.hidden {display:none;}
