/* components.css */
/* Small Pieces that belong in a lot of places */

/* Header/nav/title logo stuff */
/* Header specific styles */
body > header {
	text-align: center;
	padding: 1rem 0rem;
	position: fixed;
	top: 0;
	left:0;
	right:0;
	z-index:2000;
	width:100%;
	-webkit-transition: opacity 0.6s 0s ease;
	-moz-transition: opacity 0.6s 0s ease;
	-o-transition: opacity 0.6s 0s ease;
	transition: opacity 0.6s 0s ease;
	pointer-events: none;
	opacity: 0.0;
}
body > header.visible {
	pointer-events: all;
	opacity: 1.0;
}
body.reg-layout > header {
	box-sizing: border-box;
	position: relative;
	text-align:left;
	opacity:1.0;
	padding:1.25rem;
	pointer-events: all;
}
body.reg-layout > header > p.logo-font {
	text-align:left;
	font-size: 1.5rem;
	line-height: 1em;
}
/* When we have reg-layout and it's small AND on the index page. */
body.reg-layout.index > header  {
	display: flex;
	flex-direction: row;
	z-index: 100;
}

body.reg-layout.index > header > a > p {
	display: block;
	max-width: 200px;
	margin-left: 1rem;
	margin-top: 0.5rem;
	text-align:left;
	font-size: 1.5rem;
	line-height: 1em;
	color:var(--heading-color);
	text-decoration:none;
}
@media (max-width: 960px) {

	body.reg-layout > header  {
		display: flex;
		flex-direction: row;
		z-index: 100;
	}

	body.reg-layout > header > a > p {
		display: block;
		max-width: 200px;
		margin-left: 1rem;
		margin-top: 0.5rem;
		text-align:left;
		font-size: 1.5rem;
		line-height: 1em;
		color:var(--heading-color);
		text-decoration:none;
	}
}

@media (max-width: 600px) {
	header > p.logo-font {
		display: none;
	}
}

body > header a.symbol-link {
	display: inline-block;
	position: absolute;
	left: 4rem;
	width:2rem;
	height:2rem;
	max-width: 2rem;
	pointer-events:all;
	background-size: 2rem 2rem;
	background-image: url("/images/logo-green.svg");
	background-position: center center;
	background-repeat: no-repeat;
	text-decoration:none;
	text-indent:-5000px;
	color:transparent;
	font-size: 0px;
}
body.reg-layout > header a.symbol-link {
	display:block;
	position: relative;
	left: initial;
	width:4rem;
	height:4rem;
	max-width: 4rem;
	background-size: 4rem 4rem;
	background-image: url("/images/logo.png");
	background-image: url("/images/logo-original.png");
	margin:0;
	margin-bottom: 0.625rem;
}

body > header img {
	display: inline-block;
	position: absolute;
	left: 4rem;
	width: 2rem;
	max-width: 2rem;
	pointer-events:none;
}

body.reg-layout > header img {
	position: relative;
	max-width: 4rem;
	left:initial;
}

header > a > p, header > a {
	color:var(--silver-color);
	text-decoration: none;
}

body.reg-layout > header > a > p, body.reg-layout > header > a  {
	text-align:left;
	font-size: 1.5rem;
	line-height: 1em;
	color:var(--heading-color);
	text-decoration:none;
}

/* Nav styles: */
/* Nav specific styles */
nav { position: relative; }
body > nav ul {
	margin: 2rem 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	justify-content: center;
	gap: 1.5rem;
	font-size: 1.3rem;
	font-weight: bold;
	z-index: 900;
}
@media (max-width: 600px) {
	body.reg-layout > nav ul {
		font-size: 1rem;
	}
}
body > nav a {
	text-decoration: none;
}
body.reg-layout > nav {
	padding:1.25rem;
}
body.reg-layout > nav ul {
	display:flex;
	flex-direction: column;
	@media (max-width: 960px) {
		flex-direction: row;
		margin: 0;
		justify-content: end;
	}
}
@media (max-width: 960px) {
	.reg-layout .item-menu {
		display:flex;
		flex-direction: column-reverse;
	}
	body.reg-layout > nav ul {
		flex-direction: row;
		margin: 0;
		justify-content: end;
	}
}
body.reg-layout > nav a {
	color: black;
}
body.reg-layout > nav a:hover {
	color:var(--action-color);
	/* background:black; */
}
body.reg-layout #open-close-menu-button {
	position: absolute;
	top: 1.5rem;
	right: 0rem;
	width:120px;
	height: auto;
	display:flex;
	flex-direction:column;
	align-items: center;
	cursor:pointer;
	z-index: 1000;
	user-select:none;
}
#open-close-menu-button {
	display:none;
}
@media (min-width: 961px) {
	body.reg-layout #open-close-menu-button {
		display:none;
	}
}
#open-close-menu-button span {
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 600;
	text-decoration:none;
	font-size:1.5rem;
	line-height:1.5em;
	text-align: center;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	transform: rotateX(0deg);
	opacity: 1.0;
	transform-origin: top center;
}
#open-close-menu-button svg {
	transition: transform 0.3s ease-in-out;
	transform: rotateX(0deg) translateY(0rem);
}
#open-close-menu-button.flipped svg {
	transform: rotateX(180deg) translateY(0.5rem);
}
#open-close-menu-button.flipped span {
	transform: rotateX(90deg);
	opacity: 0.0;
}

footer ul{
	display: flex;
	margin: 0 auto;
	width: auto;
	text-align: center;
	list-style: none;
	justify-content: center;
	gap: 1rem;
	padding:0;
	flex-wrap:wrap;
}

/* @keyframes rising {
	from { transform: scale(0.10); }
	to   { transform: scale(1.0); }
} */

sun {
	display:block;
	position:absolute;
	right: -200px;
	bottom:-290px;
	z-index:001;
	width: 500px;
	height:500px;
	background-color: var(--pr-brand-yellow);
	border-radius:2000px;
	transition: transform 10s ease-in-out;
	/* box-shadow:
	0 0 0px  00px  var(--pr-brand-yellow),
	0 0 0   500px  var(--pr-brand-apricot),
	0 0 0px 500px  var(--pr-brand-apricot),
	0 0 0   1000px var(--pr-brand-magenta),
	0 0 0px 1000px var(--pr-brand-magenta),
	0 0 0   1500px var(--pr-brand-purple),
	0 0 0px 1500px var(--pr-brand-purple),
	0 0 0   2000px var(--pr-brand-midnight),
	0 0 0px 2000px var(--pr-brand-midnight); */
	transform: scale(0.10);
	/* animation: 10s alternate rising; */
	/* animation-fill-mode: both; */
}
sun corona     { display:block;position:absolute; width:500px;height:500px;border-radius:250px;transform:scale(1.0);}
sun corona.one   {background-color: var(--pr-brand-yellow);transform:scale(1.0);}
sun corona.two   {background-color: var(--pr-brand-apricot);transform:scale(2.0);}
sun corona.three {background-color: var(--pr-brand-magenta);transform:scale(3.0);}
sun corona.four  {background-color: var(--pr-brand-purple);transform:scale(4.0);}
sun corona.five  {background-color: var(--pr-brand-midnight);transform:scale(5.0);}

/* sun:after {
	content:"";
	display:block;
	position: absolute;
	left:0;right:0;top:0;bottom:0;
	width:500px;height:500px;
	border-radius:500px;
	background-color: var(--pr-brand-apricot);
	transform:scale(2.0);
	z-index:9000;
}
sun:after:after {
	content:"";
	display:block;
	position: absolute;
	left:50%;top:50%;
	margin-left:-250px;
	margin-top:-250px;
	width:500px;height:500px;
	border-radius:500px;
	background-color: var(--pr-brand-yellow);
	z-index:1000;
} */
/*
:root {
	--pr-brand-yellow:   #FFA01E;
	--pr-brand-apricot:  #FF4678;
	--pr-brand-magenta:  #EB00AA;
	--pr-brand-purple:   #A000C8;
	--pr-brand-midnight: #5A00B4;
} */

.services-container {
	width:auto;
	height:auto;
	display:block;
	position:relative;
	z-index:1000;
	margin-top:4rem;
	margin-bottom:4rem;
	margin-left:auto;
	margin-right:auto;
	width:100%;
	max-width: 768px;
	min-height:350px;
}

.services-box {
	width:100%;
	max-width: 768px;
	min-height:350px;
	height:auto;
	display:block;
	position:relative;
	z-index:1000;
	box-sizing:border-box;
	padding: 3rem 7rem;

	background: var(--pr-brand-midnight);
	/* box-shadow: 0 8px 57px 11px rgba(90,0,180,0.00); */
	border-radius: 55px;
	transition: box-shadow 1s ease-in-out;
}

/* .services-container.active .services-box {
	box-shadow: 0 8px 57px 11px rgba(90,0,180,0.42);
} */

.services-box *:last-child { margin-bottom: 0rem;}

.service-icon {
	display:block;
	position:absolute;
	z-index:100;
	pointer-events: none;
	width:132px;
	height:132px;
	left: 50%;
	margin-left: -66px;
	top: 50%;
	margin-top: -66px;
	transform: translateY(0rem) translateX(0rem) rotate(0deg);
	transition: transform 0.73s cubic-bezier(0.87, 0, 0.13, 1);
	transition-delay: 0.1s;

}
.services-container.active .service-icon#iphone {transform:translateY(-12rem) translateX(-25rem) rotate(-9deg);
	transition-delay: 0.1s;
}
.services-container.active .service-icon#ruby {transform:translateY(0rem) translateX(-32rem);
	transition-delay: 0.2s;
}
.services-container.active .service-icon#abstract {transform:translateY(12rem) translateX(-25rem);
	transition-delay: 0.3s;
}
.services-container.active .service-icon#javascript {transform:translateY(-12rem) translateX(30rem) rotate(7deg);
	transition-delay: 0.35s;
}
.services-container.active .service-icon#prologue {transform:translateY(5rem) translateX(30rem);
	transition-delay: 0.2s;
}
.services-container.active .service-icon#swift {transform:translateY(16rem) translateX(21rem) rotate(-12deg);
	transition-delay: 0.45s;
}

@media (max-width: 800px) {
	.services-box {
		max-width:calc(100vw - 14rem);
		padding:3rem 5rem;
		margin:0 auto;
	}
	/* .service-icon {z-index:2000;} */
	.services-container.active .service-icon#iphone {transform:translateY(-12rem) translateX(-10rem) rotate(-9deg);}
	.services-container.active .service-icon#ruby {transform:translateY(0rem) translateX(-17rem);}
	.services-container.active .service-icon#abstract {transform:translateY(11rem) translateX(-13rem);}
	.services-container.active .service-icon#javascript {transform:translateY(-12rem) translateX(15rem) rotate(7deg);}
	.services-container.active .service-icon#prologue {transform:translateY(4rem) translateX(16rem);}
	.services-container.active .service-icon#swift {transform:translateY(13rem) translateX(11rem) rotate(-12deg);}
}

@media (max-width: 650px) {
	.services-box {
		padding: 3rem 6rem;
		background-color: color(display-p3 0.353 0.00 0.706 / 0.7);
		max-width:100%;
	}

}


#ruby #icon-ruby #ruby-shape { fill: var(--deep-red); }
#icon-swift #icon-shape #swift-background { fill: var(--swift-orange); }
#icon-javascript #javascript-shape { fill: var(--js-yellow); }
#icon-prologue #first   { fill: var(--pr-brand-midnight); }
#icon-prologue #second  { fill: var(--pr-brand-purple); }
#icon-prologue #third   { fill: var(--pr-brand-magenta); }
#icon-prologue #fourth  { fill: var(--pr-brand-apricot); }
#icon-prologue #fifth   { fill: var(--pr-brand-yellow); }


/* ordinaled text that animates when exposed to sunlight. */
#measured { overflow: hidden; }
.ordinaled {
	margin:5rem;
	transition: all 1s ease-in-out;
	transition-delay: 0.1s;
	transform: translateX(0rem);
	opacity:0.0;
}
.ordinaled span.ordinal {
	display:inline-block;
	width:2rem;
	height: 2rem;
	margin-right:1.25rem;
	border-radius:1rem;
}
.ordinaled span.ordinal.purple  { background: var(--pr-brand-purple) ;}
.ordinaled span.ordinal.magenta { background: var(--pr-brand-magenta);}
.ordinaled span.ordinal.apricot { background: var(--pr-brand-apricot);}
.ordinaled span.ordinal.yellow  { background: var(--pr-brand-yellow) ;}

.ordinaled.purple { transform: translateX(-5rem);}
.ordinaled.magenta { transform: translateX(0rem);}
.ordinaled.apricot { transform: translateX(5rem);}
.ordinaled.yellow { transform: translateX(10rem);}
#measured.visible .ordinaled.purple  { transform: translateX(0rem);transition-delay: 0.1s; opacity:1.0;}
#measured.visible .ordinaled.magenta { transform: translateX(5rem);transition-delay: 0.2s; opacity:1.0;}
#measured.visible .ordinaled.apricot { transform: translateX(10rem);transition-delay: 0.3s; opacity:1.0;}
#measured.visible .ordinaled.yellow  { transform: translateX(15rem);transition-delay:0.4s; opacity:1.0;}

@media (max-width: 1200px) {
	.ordinaled {margin-left:3rem;margin-right:3rem;}
}
@media (max-width: 1050px) {
	#measured.visible .ordinaled.purple  { transform: translateX(0rem);}
	#measured.visible .ordinaled.magenta { transform: translateX(3rem);}
	#measured.visible .ordinaled.apricot { transform: translateX(6rem);}
	#measured.visible .ordinaled.yellow  { transform: translateX(9rem);}
}
@media (max-width: 900px) {
	#measured.visible .ordinaled.purple,
	#measured.visible .ordinaled.magenta,
	#measured.visible .ordinaled.apricot,
	#measured.visible .ordinaled.yellow { transform: translateX(0rem);}
}

.visible-on-mobile {display:none;}
.hidden-on-mobile {display:block;}
@media (max-width: 650px) {
	.visible-on-mobile {display:block;}
	.hidden-on-mobile {display:none;}
}

@media (max-width: 400px) {
	#measured {max-width:100vw;}
	.price-card {padding: 3rem 2rem;max-width:100vw;}
	.price-card p.price {font-size:3rem;}
	.price-subtext {font-size:1.5rem;line-height:1em;}
	.price-card h2, .price-card p {font-size:1.25rem;}
	.price-card .button {font-size:1.75rem;}
	.price-card.outlined p {font-size:1.25rem;}
	.services-box {padding: 3rem 2rem;}
	sun {bottom:-190px;}
}

/* BAR */
.bar {
	background: #F2F2F2;
	min-height: 100px;
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.bar h2 { color: var(--darkened-color); }

.bar .upc {
	background: transparent;
	height: inherit;
	display: flex;
}
.bar .upc div {
	display: block;
	background: var(--darkened-color);
	width: 4px;
	height:inherit;
	margin-right: 4px;
}
.bar .upc div:nth-child(1) {width: 20px;}
.bar .upc div:nth-child(2) {width: 8px;}
.bar .upc div:nth-child(4) {width: 8px;}
.bar .upc div:nth-child(8) {width: 8px;}
.bar .upc div:nth-child(10) {width: 8px; margin-right: 8px;}
.bar .upc div:nth-child(11) {width: 8px;}
.bar .upc div:nth-child(12) {margin-right: 16px;}
.bar .upc div:nth-child(13) {margin-right: 8px;}
.bar .upc div:nth-child(14) {width: 8px;}
.bar .upc div:last-child   {width: 20px; margin-right: 24px;}

@media (max-width: 500px) {
	.bar .upc div {display:none;}
}

/* Ticker */
@keyframes ticker_anim {
	100% {
		transform: translateX(-100%);
	}
}
.ticker__body { overflow: hidden; display: block; width: 100%; }
.ticker { display: flex; }
.ticker__list { display: flex; margin-top: 20px; animation: ticker_anim 50s infinite linear; padding-right:40px;}
.ticker__item { margin-right: 20px; overflow: visible; white-space: nowrap; }

/* Testimonials */
.testimonial__container {}
.testimonial {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-width: 4px;
	border-style: solid;
	border-color: var(--pr-brand-yellow);
	border-radius: 32px;
	padding:1.5rem;
	box-sizing: border-box;
	max-width: 560px;
}
.testimonial > p { font-size: var(--step-1); }
.testimonial > h2:first-child { margin:0; text-align: center; }
.testimonial > p:nth-child(2) { font-size: var(--step-0); text-align: center; }

/* Menu page */
body.reg-layout.page.menu .item-menu,
body.reg-layout.page.menu header {
	display:none;
}

body.reg-layout.page.menu .item-main {
	border-left: none;
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 3;
}

/* Portfolio Samples */
.portfolio-container {
	display:flex;
	flex-direction: row-reverse;
	gap: 36px;
	justify-content: space-between;
	position:relative;
	border-radius:40px;
	background: #E6E6E6;
	padding:2.25rem;
	box-sizing: border-box;
	max-width: 1096px;
	margin-bottom:2.25rem;
}

.mini_porto_container {
	display:flex;
	flex-direction: column;
	gap: 2.25rem;
	height: 100%;
	width:100%;
}

.portfolio-container img {
	display:block;
	width:100%;
	border-radius: 17px;
}

.portfolio-text {
	font-size:1.5rem;
	color:black;
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
	font-weight:500;
}
.portfolio-pill,  .row.brightened p.portfolio-pill {
	font-size:1.5rem;
	color:white;
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 600;
	color: white;
	background: black;
	display: inline-block;
	padding: 0rem 1.25rem;
	border-radius: 100px;
}

.contains-rainbow {
	box-sizing: border-box;
	min-height: 100vh;
	position: relative;
	z-index: 1000;
	overflow: hidden;
}
@media (max-width: 500px) {
	.contains-rainbow {
		margin-top: -2rem;
	}
}
