@import 'variables';

/* MD Primer */
@font-face {
	font-family: 'MDPrimer';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("../fonts/mdprimer/MDPrimer-Bold.woff2?v=99.99") format("woff2"), url("../fonts/mdprimer/MDPrimer-Bold.woff?v=99.99") format("woff");
}

.typeface-mdprimer { /* for just using the typeface */
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
}

/* MD System */
@font-face {
	font-family: 'MDSystem'; font-style: normal; font-display: swap;
	font-weight: 400;
	src: url("../fonts/mdsystem/MDSystem-Regular.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-Regular.woff?v=99.99") format("woff");
}
@font-face {
	font-family: 'MDSystem'; font-style: italic; font-display: swap;
	font-weight: 400;
	src: url("../fonts/mdsystem/MDSystem-Italic.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-Italic.woff?v=99.99") format("woff");
}

@font-face {
	font-family: 'MDSystem'; font-style: normal; font-display: swap;
	font-weight: 500;
	src: url("../fonts/mdsystem/MDSystem-Medium.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-Medium.woff?v=99.99") format("woff");
}
@font-face {
	font-family: 'MDSystem'; font-style: italic; font-display: swap;
	font-weight: 500;
	src: url("../fonts/mdsystem/MDSystem-MediumItalic.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-MediumItalic.woff?v=99.99") format("woff");
}

@font-face {
	font-family: 'MDSystem'; font-style: normal; font-display: swap;
	font-weight: 600;
	src: url("../fonts/mdsystem/MDSystem-Dark.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-Dark.woff?v=99.99") format("woff");
}
@font-face {
	font-family: 'MDSystem'; font-style: italic; font-display: swap;
	font-weight: 600;
	src: url("../fonts/mdsystem/MDSystem-DarkItalic.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-DarkItalic.woff?v=99.99") format("woff");
}

@font-face {
	font-family: 'MDSystem'; font-style: normal; font-display: swap;
	font-weight: 700;
	src: url("../fonts/mdsystem/MDSystem-Bold.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-Bold.woff?v=99.99") format("woff");
}
@font-face {
	font-family: 'MDSystem'; font-style: italic; font-display: swap;
	font-weight: 700;
	src: url("../fonts/mdsystem/MDSystem-BoldItalic.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-BoldItalic.woff?v=99.99") format("woff");
}

@font-face {
	font-family: 'MDSystem'; font-style: normal; font-display: swap;
	font-weight: 800;
	src: url("../fonts/mdsystem/MDSystem-Heavy.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-Heavy.woff?v=99.99") format("woff");
}
@font-face {
	font-family: 'MDSystem'; font-style: italic; font-display: swap;
	font-weight: 800;
	src: url("../fonts/mdsystem/MDSystem-Heavy.woff2?v=99.99") format("woff2"), url("../fonts/mdsystem/MDSystem-Heavy.woff?v=99.99") format("woff");
}

.typeface-mdsystem { /* for just using the typeface */
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
}

/* MD Nichrome */
@font-face {
	font-family: 'MDNichrome';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../fonts/mdnichrome/MDNichrome-Black.woff2?v=99.99") format("woff2"), url("../fonts/mdnichrome/MDNichrome-Black.woff?v=99.99") format("woff");
}

.typeface-mdnichrome { /* for just using the typeface */
	font-family: 'MDNichrome', -apple-system, Helvetica, Arial, sans-serif;
}

/* MD Nichrome */
@font-face {
	font-family: 'ManifoldCF';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../fonts/manifold/ManifoldCF-Regular.woff2?v=99.99") format("woff2"), url("../fonts/manifold/ManifoldCF-Regular.woff?v=99.99") format("woff");
}

@font-face {
	font-family: 'ManifoldCF';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url("../fonts/manifold/ManifoldCF-ExtraBold.woff2?v=99.99") format("woff2"), url("../fonts/manifold/ManifoldCF-ExtraBold.woff?v=99.99") format("woff");
}

.typeface-manifoldcf { /* for just using the typeface */
	font-family: 'ManifoldCF', -apple-system, Helvetica, Arial, sans-serif;
}



/* Actual Type Stuff */

/* Headings */

.heading-one, h1 {
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 600;
	font-size: 5rem;
	line-height: 1em;
	letter-spacing:-0.04rem;
	margin-top: 0em;
	margin-bottom: 0.5em;
}

.heading-two, h2 {
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 600;
	font-size: 3.75rem;
	line-height: 3.75rem;
	letter-spacing:-0.03rem;
	margin-top: 0em;
	margin-bottom: 1.25rem;
}
.heading-two em, h2 em {
	color: var(--action-color);
}

.heading-three, h3 {
	font-size: 3rem;
	line-height: 1em;
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 600;
	margin-top: 0em;
	margin-bottom: 0.5em;
 }
.heading-four, h4 {
	font-size: 2rem;
	line-height: 1em;
	font-family: 'MDPrimer', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 600;
	margin-top: 0em;
	margin-bottom: 0.5em;
 }

.excerpt {
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 1.5em;
	/* letter-spacing:-0.012rem; */
	margin-top: 0em;
	margin-bottom: 1em;
	opacity: 0.8;
	color: var(--silver-color);
}

.paragraph, p {
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
	/* font-weight: 600; */
	font-weight: 500;
	/* font-size: 1.5rem;
	line-height: 1.5em; */
	font-size: 2rem;
	line-height: 1.5em;
	/* letter-spacing:-0.012rem; */
	margin-top: 0em;
	margin-bottom: 1em;
}
.paragraph strong { font-weight: 700; opacity:1.0; }
.paragraph em     { font-style: italic; }

.interface-text {
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 1.5em;
	margin-top: 0em;
	margin-bottom: 1rem;
}
.interface-text strong { font-weight: 700; opacity:1.0; }
.interface-text em     { font-style: italic; }


.quote {
	font-family: 'MDSystem', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 500;
	font-style: italic;
	font-size: 1.75rem;
	line-height: 2rem;
	/* letter-spacing:-0.018rem; */
	margin-top: 0em;
	margin-bottom: 1em;
}

.logo-font, p.logo-font { /* Used for the logo in the header. */
	font-family: 'MDNichrome', -apple-system, Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-size: 1.875rem;
	line-height: 2rem;
	margin-top: 0em;
	letter-spacing:1px;
	margin-bottom: 1em;
}

.title-logo { /* Used for the logo in the header. */
	font-family: 'MDNichrome', -apple-system, Helvetica, Arial, sans-serif;
	color: var(--silver-color);
	font-weight: 700;
	font-size: 3.75rem;
	line-height: 4rem;
	margin-top: -2.25rem; /* On Scroll animate this property lower and lower. */
	letter-spacing:-0.03rem;
	margin-bottom: 0.25em;
	z-index: 1000;
	-webkit-transition: transform 0.1s 0s ease;
	-moz-transition: transform 0.1s 0s ease;
	-o-transition: transform 0.1s 0s ease;
	transition: transform 0.1s 0s ease;
}

/* A content block has markup that's either been output by a wysywig, or is structured with very little special styles */
.content > h1, .content > h2, .content > h3, .content > h4, .content > p, .content > ul, .content > blockquote, .content > figure {
	max-width: 80rem;
}
/* Define heading widths: */
.content > h1 { font-size: 5rem; line-height: 1em;}
.content > h2 { font-size: 4rem; line-height: 1em;}
.content > h3 { font-size: 3rem; line-height: 1em;}
.content > h4 { font-size: 2rem; line-height: 1em;}
.content > h1, .content > h2, .content > h3, .content > h4 {
	margin-top: 2em;
	margin-bottom: 0.75em;
}
.content > p {
	font-size: 2rem;
	line-height:1.5em;
	font-weight:600;
	text-align:justify;
}
.content > p strong { font-weight:600; }
.content > p em {font-style: italic;}
.content > p a { color: var(--action-color); padding: 0.10rem 0.35rem; text-decoration: none; margin-left: -0.35rem; margin-right: -0.35rem;}
.content > p a:hover {color:black; background:var(--action-color); text-decoration: none;}
.content > ul {}
.content > ul li {}
.content > blockquote {}
.content > figure {}
.content > figure img {}
.content > figure figcaption {}
/* <figure>
	<img src="pic_trulli.jpg" alt="Trulli" style="width:100%">
	<figcaption>Fig.1 - Trulli, Puglia, Italy.</figcaption>
</figure> */

/* Reveal effect */
.to-reveal {
	transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
	opacity: 0.0;
	transform: translateY(2rem);
}
.to-reveal.revealed {
	opacity: 1.0;
	transform: translateY(0rem);
}

.content > * {
	opacity: 0.0;
	transform: translateY(2rem);
}

@keyframes reveal {
	from {opacity: 0.0; transform: translateY(2rem);}
	to {opacity: 1.0; transform: translateY(0rem);}
}
.content > *:nth-child(1)    { animation-delay:0s; }
.content > *:nth-child(2)    { animation-delay:0.15s; }
.content > *:nth-child(3)    { animation-delay:0.3s; }
.content > *:nth-child(4)    { animation-delay:0.45s; }
.content > *:nth-child(5)    { animation-delay:0.6s; }
.content > *:nth-child(6)    { animation-delay:0.75s; }
.content > *:nth-child(7)    { animation-delay:0.9s; }
.content > *:nth-child(8)    { animation-delay:1.05s; }
.content > *:nth-child(9)    { animation-delay:1.20s; }
.content > *:nth-child(10)    { animation-delay:1.35s; }
.content > *:nth-child(1n+11) { animation-delay:1.5s; }
.content > * {
	animation: 0.7s 1 normal reveal;
	animation-fill-mode: forwards;
}

/* Adjusted font-sizes for mobile stuff. */
@media (max-width: 800px) {
	.heading-one, h1 { font-size: 3rem; }
	.content > h1 { font-size: 3rem;   }
	.content > h2 { font-size: 2.5rem; }
	.content > h3 { font-size: 2rem;   }
	.content > h4 { font-size: 1.5rem; }
	.content > p  { font-size: 1.5rem;}
	.content > p strong { }
	.content > p em {}
	.content > p a {}
	.content > p a:hover {}
	.content > ul {}
	.content > ul li {}
	.content > blockquote {}
	.content > figure {}
	.content > figure img {}
	.content > figure figcaption {}
}

.button-link {
	padding: 0.10rem 0.35rem;
	margin-left: -0.35rem;
	text-decoration: none;
	margin-right: -0.35rem;
}
.button-link:hover {
	color:var(--darkened-color);
	background:var(--pr-brand-yellow);
}

.button-link.opposite {
	color:var(--darkened-color);
	background:var(--pr-brand-yellow);
}
.button-link.opposite:hover {
	color:var(--pr-brand-yellow);
	background:var(--darkened-color);
}

.button-link.white {
	color:var(--darkened-color);
	background:white;
}
.button-link.white:hover {
	color:white;
	background:var(--darkened-color);
}

.button-link.pinked {
	color:white;
	background:var(--pr-brand-magenta);
}
.button-link.pinked:hover {
	color:var(--pr-brand-magenta);
	background:none;
}


/* generic type adjustments across sizes */
@media (max-width: 650px) {
	.paragraph, p {
		font-size:1.5rem;
	}
	#measured {

	}
}


/* Typeface Scale that I'm gonna use for Rocketry */
/* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1680,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

/* Type Sizes, use in font-size, example: font-size: var(--step-0); */
:root {
	--step--2: clamp(0.69rem, calc(0.67rem + 0.12vw), 0.80rem);
	--step--1: clamp(0.83rem, calc(0.79rem + 0.20vw), 1.00rem);
	--step-0: clamp(1.00rem, calc(0.94rem + 0.29vw), 1.25rem);
	--step-1: clamp(1.20rem, calc(1.11rem + 0.43vw), 1.56rem);
	--step-2: clamp(1.44rem, calc(1.32rem + 0.60vw), 1.95rem);
	--step-3: clamp(1.73rem, calc(1.56rem + 0.84vw), 2.44rem);
	--step-4: clamp(2.07rem, calc(1.84rem + 1.15vw), 3.05rem);
	--step-5: clamp(2.49rem, calc(2.18rem + 1.56vw), 3.82rem);
}

/* LineHeight pairings, use in line-height, example: line-height: var(--lh-step-0); */
:root {
	--lh-step--2: 1.75;
	--lh-step--1: 1.75;
	--lh-step-0:  1.7;
	--lh-step-1:  1.5;
	--lh-step-2:  1.333;
	--lh-step-3:  1.25;
	--lh-step-4:  1.2;
	--lh-step-5:  1.2;
}

/*  Type size pairings, use in font, example: font: var(--font-0); */
:root {
	--font--2: 500 var(--step--2) /  var(--lh-step--2) 1.7 sans-serif;
	--font--1: 500 var(--step--1) /  var(--lh-step--1) 1.7 sans-serif;
	--font-0:  500 var(--step-0)  /  var(--lh-step-0)  1.7 sans-serif;
	--font-1:  500 var(--step-1)  /  var(--lh-step-1)  1.7 sans-serif;
	--font-2:  500 var(--step-2)  /  var(--lh-step-2)  1.7 sans-serif;
	--font-3:  500 var(--step-3)  /  var(--lh-step-3)  1.7 sans-serif;
	--font-4:  500 var(--step-4)  /  var(--lh-step-4)  1.7 sans-serif;
	--font-5:  500 var(--step-5)  /  var(--lh-step-5)  1.7 sans-serif;
}
